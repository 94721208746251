<template>
  <div>
    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Project Code</label>

            <b-form-input
              size="lg"
              v-model="form.project.code"
              disabled
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Project Name</label>

            <b-form-input
              id="input-large"
              size="lg"
              v-model="form.project.name"
              disabled
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Nickname</label>

            <b-form-input
              id="input-large"
              size="lg"
              v-model="form.project.nickname"
              disabled
            ></b-form-input>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Date</label>

            <b-form-input
              id="input-large"
              size="lg"
              type="date"
              v-model="form.date"
              disabled
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Place</label>

            <b-form-input
              id="input-large"
              size="lg"
              v-model="form.place"
              disabled
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Agenda</label>

            <b-form-input
              id="input-large"
              size="lg"
              v-model="form.subject"
              disabled
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Leader</label>

            <b-form-input
              id="input-large"
              size="lg"
              v-model="form.leader"
              disabled
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Status</label>

            <b-form-input
              id="input-large"
              size="lg"
              v-model="form.status"
              disabled
            ></b-form-input>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-custom p-5 gutter-b">
      <b-tabs content-class="mt-3">
        <b-tab title="Point MOM" active>
          <div class="d-flex justify-content-end mt-5 mb-5">
            <b-button class="ml-auto" @click="addPointMom" variant="success">
              <i class="flaticon2-plus"></i> Add MOM Point
            </b-button>
          </div>

          <div class="card-body p-0">
            <complete-table :data="filteredPointMom" :loading="loading">
              <template #header>
                <tr class="text-left">
                  <th class="pl-7">Point</th>
                  <th class="pl-7">Description</th>
                  <th class="pl-7">Cause Analysis</th>
                  <th class="pl-7">Deadline</th>
                  <th class="pl-7">Status</th>
                  <th class="pl-7">PIC</th>
                  <th class="pl-7">Follow Up</th>
                  <th class="pl-7">Action</th>
                </tr>
              </template>

              <template #defaultBody>
                <template v-for="(point, i) in filteredPointMom">
                  <tr v-bind:key="i" class="border-0" v-if="!point.delete">
                    <td class="pl-0 pt-8" style="min-width: 250px">
                      <template v-if="currentIndex !== i">
                        <span
                          class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ point.point }}
                        </span>
                      </template>
                      <template v-if="currentIndex === i">
                        <input
                          type="text"
                          name=""
                          class="form-control"
                          v-model="point.point"
                          id=""
                        />
                      </template>
                    </td>

                    <td class="pl-0 pt-8" style="min-width: 250px">
                      <template v-if="currentIndex !== i">
                        <span
                          class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ point.description }}
                        </span>
                      </template>
                      <template v-if="currentIndex === i">
                        <input
                          type="text"
                          name=""
                          class="form-control"
                          v-model="point.description"
                          id=""
                        />
                      </template>
                    </td>

                    <td class="pl-0 pt-8">
                      <template v-if="currentIndex !== i">
                        <span
                          class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ point.cause }}
                        </span>
                      </template>
                      <template v-if="currentIndex === i">
                        <input
                          type="text"
                          name=""
                          class="form-control"
                          v-model="point.cause"
                          id=""
                        />
                      </template>
                    </td>

                    <td class="pl-0 pt-8" style="min-width: 200px">
                      <template v-if="currentIndex !== i">
                        <span
                          class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{
                            point.deadline
                              ? moment(point.deadline).format(
                                  "DD/MM/YYYY, h:mm:ss a"
                                )
                              : point.deadline
                          }}
                        </span>
                      </template>
                      <template v-if="currentIndex === i">
                        <date-picker
                          v-model="point.deadline"
                          type="datetime"
                          format="DD/MM/YYYY hh:mm:ss"
                        />
                      </template>
                    </td>

                    <td class="pl-0 pt-8">
                      <template v-if="currentIndex !== i">
                        <span
                          class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ point.status }}
                        </span>
                      </template>
                      <template v-if="currentIndex === i">
                        <b-form-select
                          v-model="point.status"
                          :options="options.statuses"
                          size="lg"
                        ></b-form-select>
                      </template>
                    </td>

                    <td class="pl-0 pt-8" style="min-width: 250px">
                      <template v-if="currentIndex !== i">
                        <span
                          class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ point.pic ? point.pic.name : "-" }}
                        </span>
                      </template>
                      <template v-if="currentIndex === i">
                        <model-list-select
                          :list="formattedPicOptions"
                          option-value="code"
                          option-text="name"
                          @input="pilihPic($event, i)"
                          v-model="point.pic"
                          placeholder="Select PIC"
                        />
                      </template>
                    </td>

                    <td class="pl-0 pt-8" style="min-width: 150px">
                      <template v-if="currentIndex !== i">
                        <span
                          class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ point.follow_up }}
                        </span>
                      </template>
                      <template v-if="currentIndex === i">
                        <input
                          type="text"
                          name=""
                          class="form-control"
                          v-model="point.follow_up"
                          id=""
                        />
                      </template>
                    </td>

                    <td class="pl-0 pt-8" style="min-width: 150px">
                      <a
                        class="btn btn-light-success font-weight-bolder font-size-sm mr-2"
                        @click="currentIndex = i"
                        v-if="currentIndex !== i"
                      >
                        <i class="flaticon-edit"></i>
                      </a>

                      <a
                        class="btn btn-light-success font-weight-bolder font-size-sm mr-2"
                        @click="currentIndex = null"
                        v-if="currentIndex === i"
                      >
                        <i class="flaticon2-check-mark"></i>
                      </a>

                      <a
                        @click="showDeleteModal(i)"
                        class="btn btn-light-danger font-weight-bolder font-size-sm mr-2"
                      >
                        <i class="flaticon2-rubbish-bin-delete-button"></i>
                      </a>
                    </td>
                  </tr>
                </template>
              </template>
            </complete-table>
          </div>

          <div class="d-flex justify-content-end mt-5">
            <b-button class="ml-auto" variant="success" @click="simpanPointMom">
              <i class="flaticon2-check-mark"></i> Save
            </b-button>
          </div>
        </b-tab>

        <b-tab title="Attendees">
          <div class="d-flex justify-content-end mt-5 mb-5">
            <b-button class="ml-auto" variant="success" @click="addPeserta">
              <i class="flaticon2-plus"></i> Add Participant
            </b-button>
          </div>

          <complete-table :data="filteredAttendant" :loading="loading">
            <template #header>
              <tr class="text-left">
                <th class="pl-7">Name</th>
                <th class="pl-7">Position</th>
                <th class="pl-7">Email</th>
                <th class="pl-7">Signature</th>
                <th class="pl-7">Action</th>
              </tr>
            </template>

            <template #defaultBody>
              <template v-for="(attendant, i) in filteredAttendant">
                <tr v-bind:key="i" class="border-0" v-if="!attendant.delete">
                  <td class="pl-0 pt-8" style="min-width: 200px">
                    <template v-if="currentIndexAttendant !== i">
                      <span
                        class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                      >
                        {{ attendant.name }}
                      </span>
                    </template>
                    <template v-if="currentIndexAttendant === i">
                      <input
                        type="text"
                        name=""
                        class="form-control"
                        v-model="attendant.name"
                        id=""
                      />
                    </template>
                  </td>

                  <td class="pl-0 pt-8" style="min-width: 200px">
                    <template v-if="currentIndexAttendant !== i">
                      <span
                        class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                      >
                        {{ attendant.position }}
                      </span>
                    </template>
                    <template v-if="currentIndexAttendant === i">
                      <input
                        type="text"
                        name=""
                        class="form-control"
                        v-model="attendant.position"
                        id=""
                      />
                    </template>
                  </td>

                  <td class="pl-0 pt-8">
                    <template v-if="currentIndexAttendant !== i">
                      <span
                        class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                      >
                        {{ attendant.email }}
                      </span>
                    </template>
                    <template v-if="currentIndexAttendant === i">
                      <input
                        type="email"
                        name=""
                        class="form-control"
                        v-model="attendant.email"
                        id=""
                      />
                    </template>
                  </td>

                  <td class="pl-0 pt-8" style="min-width: 200px">
                    <template v-if="currentIndexAttendant !== i">
                      <span
                        class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                      >
                        {{ attendant.signature }}
                      </span>
                    </template>

                    <template v-if="currentIndexAttendant === i">
                      <b-form-file
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        @change="uploadSignature($event, i)"
                      ></b-form-file>
                    </template>
                  </td>

                  <td class="pl-0 pt-8" style="min-width: 250px">
                    <a
                      class="btn btn-light-success font-weight-bolder font-size-sm mr-2"
                      @click="currentIndexAttendant = i"
                      v-if="currentIndexAttendant !== i"
                    >
                      <i class="flaticon-edit"></i>
                    </a>

                    <a
                      class="btn btn-light-success font-weight-bolder font-size-sm mr-2"
                      @click="currentIndexAttendant = null"
                      v-if="currentIndexAttendant === i"
                    >
                      <i class="flaticon2-check-mark"></i>
                    </a>

                    <a
                      @click="showDeleteAttendatModal(i)"
                      class="btn btn-light-danger font-weight-bolder font-size-sm"
                    >
                      <i class="flaticon2-rubbish-bin-delete-button"></i>
                    </a>

                    <a
                      v-if="attendant.signature"
                      @click="
                        downloadFile(
                          attendant.signature,
                          getFileNameFromUrl(attendant.signature)
                        )
                      "
                      class="btn btn-light-primary font-weight-bolder font-size-sm ml-2"
                    >
                      <i class="flaticon-download"></i>
                    </a>
                  </td>
                </tr>
              </template>
            </template>
          </complete-table>

          <div class="d-flex justify-content-end mt-5">
            <b-button
              class="ml-auto"
              variant="success"
              @click="simpanAttendante"
            >
              <i class="flaticon2-check-mark"></i> Save
            </b-button>
          </div>
        </b-tab>

        <b-tab title="Attachment">
          <div class="d-flex justify-content-end mt-5 mb-5">
            <b-button class="ml-auto" variant="success" @click="addLampiran">
              <i class="flaticon2-plus"></i> Add Attachment
            </b-button>
          </div>

          <complete-table :data="files" :loading="loading">
            <template #header>
              <tr class="text-left">
                <th class="pl-7">File</th>
                <th class="pl-7">Action</th>
              </tr>
            </template>

            <template #defaultBody>
              <template v-for="(file, i) in files.filter((c) => c.delete != 1)">
                <tr v-bind:key="i" class="border-0">
                  <td class="pl-0 pt-8" style="min-width: 200px">
                    <template v-if="currentIndexFile !== i">
                      <span
                        class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                      >
                        {{ file.filename }}
                      </span>
                    </template>

                    <template v-if="currentIndexFile === i">
                      <b-form-file
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        @change="uploadAttachment($event, i)"
                      ></b-form-file>
                    </template>
                  </td>

                  <td class="pl-0 pt-8" style="min-width: 150px">
                    <a
                      class="btn btn-light-success font-weight-bolder font-size-sm mr-2"
                      @click="currentIndexFile = i"
                      v-if="currentIndexFile !== i"
                    >
                      <i class="flaticon-edit"></i>
                    </a>

                    <a
                      class="btn btn-light-success font-weight-bolder font-size-sm mr-2"
                      @click="currentIndexFile = null"
                      v-if="currentIndexFile === i"
                    >
                      <i class="flaticon2-check-mark"></i>
                    </a>

                    <a
                      @click="showDeleteAttachmentModal(i)"
                      class="btn btn-light-danger font-weight-bolder font-size-sm mr-2"
                    >
                      <i class="flaticon2-rubbish-bin-delete-button"></i>
                    </a>

                    <a
                      @click="downloadFile(file.file, file.filename)"
                      class="btn btn-light-primary font-weight-bolder font-size-sm mr-2"
                    >
                      <i class="flaticon-download"></i>
                    </a>
                  </td>
                </tr>
              </template>
            </template>
          </complete-table>

          <div class="d-flex justify-content-end mt-5">
            <b-button class="ml-auto" variant="success" @click="simpanFiles">
              <i class="flaticon2-check-mark"></i> Save
            </b-button>
          </div>
        </b-tab>
      </b-tabs>
    </div>

    <b-modal ref="delete-mom" hide-footer title="Hapus MoM" centered>
      <div class="d-block text-center mb-5 pb-5 pt-5">
        Are You Sure Want To Delete mom ?
      </div>

      <div class="d-flex mt-5">
        <b-button
          class="btn btn-success mr-2"
          block
          @click="deletePointMom"
          ref="kt_delete_mom"
          >Delete</b-button
        >

        <b-button class="btn btn-secondary mt-0" block @click="closeDeleteModal"
          >Cancel</b-button
        >
      </div>
    </b-modal>

    <b-modal ref="delete-attach" hide-footer title="Hapus Attachment" centered>
      <div class="d-block text-center mb-5 pb-5 pt-5">
        Are You Sure Want To Delete Attachment ?
      </div>

      <div class="d-flex mt-5">
        <b-button
          class="btn btn-success mr-2"
          block
          @click="deleteLampiran"
          ref="kt_delete_attachment"
          >Delete</b-button
        >

        <b-button
          class="btn btn-secondary mt-0"
          block
          @click="closeDeleteAttachmentModal"
          >Cancel</b-button
        >
      </div>
    </b-modal>

    <b-modal ref="delete-attendat" hide-footer title="Hapus Attendant" centered>
      <div class="d-block text-center mb-5 pb-5 pt-5">
        Are You Sure Want To Delete attendant ?
      </div>

      <div class="d-flex mt-5">
        <b-button
          class="btn btn-success mr-2"
          block
          @click="deleteAttendat"
          ref="kt_delete_attendant"
          >Delete</b-button
        >

        <b-button
          class="btn btn-secondary mt-0"
          block
          @click="closeDeleteAttendatModal"
          >Cancel</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapState } from "vuex";
import { GET_MEETING_BY_ID } from "../../../../core/services/store/meeting.module";
import CompleteTable from "../../../content/widgets/advance-table/CompleteTable";
// import OnEditRow from "../../../content/widgets/advance-table/row/OnEditRow";
import ApiService from "../../../../core/services/api.service";
import { showToast } from "../../../../core/helper/toast.helper";
// import { formattedOptions } from "../../../../core/helper/array-validation.helper";
import { GET_PIC } from "../../../../core/services/store/pic.module";
import { arrayMoreThanOne } from "../../../../core/helper/array-validation.helper";
import { GET_STATUS_OPTION } from "../../../../core/services/store/status.module";
import { ModelListSelect } from "vue-search-select";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "MeetingUpdate",
  components: {
    CompleteTable,
    ModelListSelect,
    DatePicker,
    // OnEditRow,
  },
  data() {
    return {
      currentIndex: null,
      currentIndexAttendant: null,
      currentIndexFile: null,
      item: {
        value: "",
        text: "",
      },
      form: {
        project: {
          code: null,
          nickname: null,
          name: null,
        },
        code: null,
        date: null,
        place: null,
        subject: null,
        pic: null,
        leader: null,
        division: null,
        department: null,
        status: null,
      },
      deleteIndex: null,
      files: [],
      attendants: [],
      momPoints: [],
      selectedParticipant: {
        id: null,
      },
      editIndex: null,
      options: {
        statuses: [
          { value: null, text: "Choose Status" },
          { value: "open", text: "Open" },
          { value: "close", text: "Close" },
        ],
      },
    };
  },

  computed: {
    ...mapState({
      loading: (state) => state.meeting.loading,
      meeting: (state) => state.meeting.meeting?.data,
      pic: (state) => state.pic.pics?.data,
      statuses: (state) => state.status.status?.data,
    }),

    formattedStatusOptions() {
      let status = [];

      if (arrayMoreThanOne(this.statuses)) {
        status = this.statuses.map((scope) => {
          return {
            value: scope.code,
            text: scope.name,
          };
        });
      }

      return [{ value: null, text: "Choose Status" }, ...status];
    },

    picOptions() {
      return this.pic;
    },

    formattedPicOptions() {
      let status = [];

      if (arrayMoreThanOne(this.pic)) {
        status = this.pic.map((pic) => {
          return {
            code: pic.code,
            name: pic.name,
          };
        });
      }

      return [{ code: null, name: "Choose Pic" }, ...status];
    },

    filteredPointMom() {
      return this.momPoints;
    },

    filteredAttendant() {
      return this.attendants;
    },
  },

  watch: {
    meeting() {
      Object.assign(this.form, {
        project: {
          code: this.meeting?.meeting?.project?.code,
          nickname: this.meeting?.meeting?.project?.nickname,
          name: this.meeting?.meeting?.project?.name,
        },
        code: this.meeting?.meeting?.code,
        date: this.meeting?.meeting?.date?.split(" ")[0],
        place: this.meeting?.meeting?.place,
        subject: this.meeting?.meeting?.subject,
        pic: this.meeting?.meeting?.pic,
        leader: this.meeting?.meeting?.leader,
        division: this.meeting?.meeting?.division,
        department: this.meeting?.meeting?.department,
        status: this.meeting?.meeting?.status,
      });

      this.momPoints = this.meeting?.mom_point?.map((meeting) => {
        return {
          ...meeting,
          delete: 0,
        };
      });

      this.attendants = this.meeting?.attendant?.map((attendant) => {
        return {
          ...attendant,
          delete: 0,
        };
      });
      this.files = this.meeting?.file;
    },
  },

  methods: {
    closeDeleteModal() {
      this.$refs["delete-mom"].hide();
    },

    showDeleteModal(index) {
      this.deleteIndex = index;

      this.$refs["delete-mom"].show();
    },

    closeDeleteAttachmentModal() {
      this.$refs["delete-attach"].hide();
    },

    showDeleteAttachmentModal(index) {
      this.deleteIndex = index;

      this.$refs["delete-attach"].show();
    },

    closeDeleteAttendatModal() {
      this.$refs["delete-attendat"].hide();
    },

    showDeleteAttendatModal(index) {
      this.deleteIndex = index;

      this.$refs["delete-attendat"].show();
    },

    getFileNameFromUrl(url) {
      const splits = url.split("/");

      const length = splits.length;

      if (url) {
        return splits[length - 1];
      }

      return null;
    },

    downloadFile(url, fileName) {
      ApiService.setHeader();

      ApiService.postDownload("ftp/download", {
        file_url: url,
      })
        .then((response) => {
          const fileURL = window.URL.createObjectURL(
            new Blob([response?.data])
          );

          const fileLink = document.createElement("a");

          fileLink.href = fileURL;

          fileLink.setAttribute("download", fileName);

          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch(() => {
          showToast("Error", "Failed download", "danger");
        });
    },

    pilihPic(data, index) {
      let jsonData = JSON.parse(JSON.stringify(data));
      this.momPoints[index].pic_code = data.value;
      this.momPoints[index].pic = jsonData;
    },

    uploadSignature(event, index) {
      ApiService.setHeader();

      const formData = new FormData();

      formData.append("file", event.target.files[0]);

      formData.append("meeting_id", this.meeting.meeting.id);

      ApiService.post("meeting/attendant/signature/upload", formData)
        .then((response) => {
          this.attendants[index].signature = response.data.data.file;
        })
        .catch(() => {
          showToast("Error", "Failed Upload Signature", "danger");
        });
    },

    uploadAttachment(event, index) {
      ApiService.setHeader();

      const formData = new FormData();

      formData.append("file", event.target.files[0]);

      formData.append("meeting_id", this.meeting.meeting.id);

      ApiService.post("meeting/file/upload", formData)
        .then((response) => {
          this.files[index].file = response.data.data.file;
          this.files[index].filename = response.data.data.filename;
        })
        .catch(() => {
          showToast("Error", "Failed Upload Signature", "danger");
        });
    },

    getPic() {
      this.$store.dispatch(GET_PIC);
    },

    simpanPointMom() {
      ApiService.setHeader();

      let data = {
        mom_point: this.momPoints,
      };

      data.mom_point.forEach((element) => {
        element.meeting = {
          id: this.meeting.meeting.id,
          code: this.meeting.meeting.code,
        };
      });

      ApiService.post("meeting/mom", data)
        .then(() => {
          // context.commit(SET_LOADING_MEETING, false);

          showToast("Success", "Saved Data Successfully");
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);

          // context.commit(SET_LOADING_MEETING, false);

          showToast("Error", "Failed Save Data", "danger");
        });
    },
    simpanAttendante() {
      ApiService.setHeader();

      let data = {
        attendant: this.attendants,
      };

      data.attendant.forEach((element) => {
        element.meeting = {
          id: this.meeting.meeting.id,
          code: this.meeting.meeting.code,
        };
      });

      ApiService.post("meeting/attendant", data)
        .then(() => {
          // context.commit(SET_LOADING_MEETING, false);

          showToast("Success", "Saved Data Successfully");
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);

          // context.commit(SET_LOADING_MEETING, false);

          showToast("Error", "Failed Saved Data", "danger");
        });
    },
    simpanFiles() {
      ApiService.setHeader();

      let data = {
        file: this.files,
      };

      data.file.forEach((element) => {
        element.meeting = {
          id: this.meeting.meeting.id,
          code: this.meeting.meeting.code,
        };
      });

      ApiService.post("meeting/file", data)
        .then(() => {
          // context.commit(SET_LOADING_MEETING, false);

          showToast("Success", "Saved Data Successfully");
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);

          // context.commit(SET_LOADING_MEETING, false);

          showToast("Error", "Failed Saved Data", "danger");
        });
    },
    deleteLampiran() {
      //this.files.splice(i, 1);
      const file = JSON.parse(JSON.stringify(this.files));
      file[this.deleteIndex].delete = 1;

      console.log("lampiran", file);

      this.files = [];
      this.files = file;

      this.closeDeleteAttachmentModal();
    },
    deleteAttendat() {
      const attendants = JSON.parse(JSON.stringify(this.attendants));

      attendants[this.deleteIndex].delete = 1;
      this.attendants = [];
      this.attendants = attendants;

      this.closeDeleteAttendatModal();
    },
    addLampiran() {
      this.files.push({
        id: null,
        file: "",
        filename: "",
      });
    },
    addPointMom() {
      this.momPoints.push({
        id: null,
        point: "",
        description: "",
        deadline: "",
        status: "",
        pic_code: "",
        response: "response",
        cause: "",
        follow_up: "",
        pic: {
          code: "",
          name: "",
        },
      });
    },
    addPeserta() {
      this.attendants.push({
        id: null,
        name: "",
        position: "",
        email: "",
        signature: "",
        attend: 1,
        department_code: "",
        division_code: "",
        department: {
          code: "",
          name: "",
        },
        division: {
          code: "",
          name: "",
        },
      });
    },
    deletePointMom() {
      const momPoints = JSON.parse(JSON.stringify(this.momPoints));

      momPoints[this.deleteIndex].delete = 1;

      this.momPoints = [];

      this.momPoints = momPoints;

      this.closeDeleteModal();
    },

    getMeetingDetail() {
      this.$store.dispatch(GET_MEETING_BY_ID, {
        id: this.$route.params.id,
      });
    },

    toIndexPage() {
      this.$router.push({
        name: "meeting",
      });
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Detail Meeting" }]);
    this.$store.dispatch(GET_STATUS_OPTION);

    this.getPic();

    this.getMeetingDetail();
  },
};
</script>
